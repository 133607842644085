import React, { useState } from "react"
import { navigate } from "gatsby"

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


export default function Pricing(props) {
  const [email, setEmail] = useState(props?.value ?? '');
  const [number, setNumber] = useState(props?.value ?? '');

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = event => {
    event.preventDefault()

    if (email) {
      fetch("https://squid-app-dph2q.ondigitalocean.app/api/v1/email-signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, phone: number, key: "THISKEYBUNIQUEYAH"}),
      })
      .then(() => navigate("/thanks"))
      .catch(error => {
          setOpen(true);

          fetch("https://api.getwaitlist.com/api/v1/waiter", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email,  api_key: "0GB3U2" }),
          })
          .then(() => navigate("/thanks"))
          .catch(error => alert(error))
      })
    }
  }

  return (
    <div id="pricing" className="w-full mx-auto mt-40 mb-20 text-center md:w-7/12">
      <div className="relative z-0 w-full">
        <div className="rounded shadow-md hover:shadow-lg overflow-hidden mb-4">
          <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6"> 
            <div className="flex justify-center">
              <span className="inline-flex px-4 py-1 rounded-full text-4xl leading-5 font-semibold tracking-wide uppercase">
                Get Early Access
              </span>
            </div>
            <div className="mt-4 flex justify-center text-6xl leading-none font-extrabold">
              {/* <span className="ml-1 mr-3 text-xl leading-8 font-medium text-gray-500">
                from
              </span>
              $9.99
              <span className="ml-1 pt-8 text-2xl leading-8 font-medium text-gray-500">
                /month
              </span> */}
            </div>
          </div>
          <div className="px-6 pt-6 pb-8 bg-white sm:p-10 sm:pt-6">
            {/* <ul>
              <li className="mt-4 flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Email and Text notifications
                </p>
              </li>
              <li className="mt-4 flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Unlimited card generation with AI
                </p>
              </li>
              <li className="mt-4 flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Unlimited Study Sets
                </p>
              </li>
              <li className="mt-4 flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  PDF/Youtube content to cards generation
                </p>
              </li>
              <li className="mt-4 flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  SMS chat reviews of learning content
                </p>
              </li>
              <li className="mt-4 flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Multiple choice and True/False cards generation
                </p>
              </li>
              <li className="mt-4 flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Pre-made MCAT/USMLE study decks
                </p>
              </li>
              <li className="mt-4 flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Learning Analytics (View Your Progress)
                </p>
              </li>
            </ul>  */}
            <form
              data-netlify="true"
              name="join"
              id="join"
              method="post"
              onSubmit={handleSubmit}
              data-netlify-recaptcha="true"
            >
              <input type="hidden" name="form-name" value="join" />
              <div className="relative pt-3">
                <input
                  type="email"
                  name="email"
                  id="email"
                  onInput={e => setEmail(e.target.value)}
                  className="mr-2 rounded-lg border-transparent  appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                  placeholder="💌 Your Email"
                  required
                />
                {/* <input
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  name="number"
                  id="number"
                  onInput={e => setNumber(e.target.value)}
                  className="mr-2 mt-4 rounded-lg border-transparent  appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                  placeholder="📞 123-456-7890"
                /> */}
              </div>
              <div className="flex items-center justify-center">
                <input
                  className="mt-4 rounded-md shadow flex items-center justify-center px-2 py-1 border border-transparent text-base leading-9 font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  type="submit"
                  value="Request Access"
                />
              </div>
            </form>
            {open && <Alert onClose={handleClose} severity="error">
              <AlertTitle>Error</AlertTitle>
              An error occured saving your email please contact support — <strong>support@quizme.ai</strong>
            </Alert>}
          </div>
        </div>
      </div>
    </div>
  )
}
