import React from "react"
import { Link } from "gatsby"

import Uni1 from "../images/harvard.svg"
import Uni2 from "../images/john-hopkins.svg"
import Uni3 from "../images/ucl.png"
import Uni4 from "../images/oxford.png"
import Uni5 from "../images/uot.svg"
import Uni6 from "../images/ucla.png"

import Company1 from "../images/notion.png"
import Company2 from "../images/coursera.svg"
import Company3 from "../images/edx.png"
import Company4 from "../images/khan.png"
import Company5 from "../images/uworld.png"
import Company6 from "../images/tpr.png"
import Company7 from "../images/aamc.png"
import Company8 from "../images/mit.png"
import Company9 from "../images/stanford.png"


export default function Companies() {
  return (
    <div className=" mx-auto text-center pb-2">
      <p className="text-xl text-gray-600">
        Learners from these places use Quiz Me Ai
      </p>
      <div className="w-full flex flex-col md:flex-row gap-4 mb-8 md:mb-0 flex-between items-center mt-5 p-8">
        {/* <div>
          <Link to="" className="block relative">
            <img
              alt="harvard"
              src={Uni1}
              className="mx-auto object-scale-down object-cover h-40 w-50 "
            />
          </Link>
        </div> */}
        <div>
          <Link to="" className="block relative">
              <img
                alt="Johns Hopkins University"
                src={Uni2}
                className="mx-auto object-scale-down object-cover h-40 w-50 "
              />
          </Link>
        </div>
        <div>
          <Link to="" className="block relative">
              <img
                alt="UCL Medicine"
                src={Uni3}
                className="mx-auto object-scale-down object-cover h-40 w-50 "
              />
          </Link>
        </div>
        <div>
          <Link to="" className="block relative">
              <img
                alt="Oxford"
                src={Uni4}
                className="mx-auto object-scale-down object-cover h-40 w-50 "
              />
          </Link>
        </div>
        <div>
          <Link to="" className="block relative">
              <img
                alt="Univeristy of Toronto"
                src={Uni5}
                className="mx-auto object-scale-down object-cover h-40 w-50 "
              />
          </Link>
        </div>
        {/* <div>
          <Link to="https://www.uclaextension.edu/" className="block relative">
              <img
                alt="UCLA"
                src={Uni6}
                className="mx-auto object-scale-down object-cover h-40 w-50  "
              />
          </Link>
        </div> */}
      </div>


      <div className="w-full flex flex-col md:flex-row gap-4 mb-8 md:mb-0 flex-between items-center mt-0 pb-8 pl-8 pr-8 pb-1">
        <div>
          <Link to="" className="block relative">
            <img
              alt="notion"
              src={Company1}
              className="mx-auto object-scale-down object-cover h-40 w-40 "
            />
          </Link>
        </div>
        {/* <div>
          <Link to="https://coursera.org/" className="block relative">
              <img
                alt="Coursera"
                src={Company2}
                className="mx-auto object-scale-down object-cover h-40 w-40 "
              />
          </Link>
        </div> */}
        {/* <div>
          <Link to="https://www.edx.org/" className="block relative">
              <img
                alt="EdX"
                src={Company3}
                className="mx-auto object-scale-down object-cover h-40 w-20 "
              />
          </Link>
        </div> */}
        <div>
          <Link to="" className="block relative">
              <img
                alt="Khan Academy"
                src={Company4}
                className="mx-auto object-scale-down object-cover h-40 w-50 "
              />
          </Link>
        </div>
        <div>
          <Link to="" className="block relative">
              <img
                alt="UWorld"
                src={Company5}
                className="mx-auto object-scale-down object-cover h-40 w-40 "
              />
          </Link>
        </div>
        <div>
          <Link to="" className="block relative">
              <img
                alt="princton review"
                src={Company6}
                className="mx-auto object-scale-down object-cover h-40 w-40  "
              />
          </Link>
        </div>
        {/* <div>
          <Link to="https://www.aamc.org//" className="block relative">
              <img
                alt="aamc"
                src={Company7}
                className="mx-auto object-scale-down object-cover h-40 w-60  "
              />
          </Link>
        </div> */}
        {/* <div>
          <Link to="" className="block relative">
              <img
                alt="mit"
                src={Company8}
                className="mx-auto object-scale-down object-cover h-40 w-60  "
              />
          </Link>
        </div> */}
        {/* <div>
          <Link to="https://www.stanford.edu/" className="block relative">
              <img
                alt="stanford"
                src={Company9}
                className="mx-auto object-scale-down object-cover h-40 w-60  "
              />
          </Link>
        </div> */}
      </div>
    </div>
  )
}
