import * as React from "react"
import { useEffect } from "react"
import { setSessionId, track } from "@amplitude/analytics-browser"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Cta from "../components/cta"
import FeaturesMain from "../components/featuresMain"
import Companies from "../components/companies"
import Pricing from "../components/pricing"
import Testimonials from "../components/testimonials"

const IndexPage = () => {
  let eventProperties = {}

  useEffect(() => {
    // fetch('https://api.ipify.org?format=json')
    // .then((response) => response.json())
    // .then((data) => {
    //   eventProperties = {
    //     userIP: data.ip,
    //     sessionId: setSessionId(Date.now())
    //   };
    //   track('HomePage Visited', eventProperties);
    // });
    track('HomePage Visited');
  },[]);

 return (
  <Layout>
    <Seo title="Learning Buddy" />
    <Hero />
    {/* <FeaturesMain /> */}
    <Companies />
    <Testimonials />
    <Pricing />
    {/* <Cta /> */}
  </Layout>
  )
}

export default IndexPage
