import React, { useState } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"

import VisibilitySensor from "react-visibility-sensor";
import { track } from "@amplitude/analytics-browser";

import { Fade } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function Hero(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            waitlistAPI
          }
        }
      }
    `
  )
  const [email, setEmail] = useState(props?.value ?? '');
  const [number, setNumber] = useState(props?.value ?? '');

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = event => {
    event.preventDefault()

    if (email) {
      fetch("https://squid-app-dph2q.ondigitalocean.app/api/v1/email-signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, phone: number, key: "THISKEYBUNIQUEYAH"}),
      })
      .then(() => navigate("/thanks"))
      .catch(error => {
          setOpen(true);

          fetch("https://api.getwaitlist.com/api/v1/waiter", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email,  api_key: "0GB3U2" }),
          })
          .then(() => navigate("/thanks"))
          .catch(error => alert(error))
      })
    }
  }

  return (
    <VisibilitySensor once>
    {({ isVisible }) => (
      // <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
      <Fade in={true} timeout={4000}>
        <section className="py-44 bg-white">
          <div className="px-12 mx-auto max-w-7xl">
            <div className="w-full mx-auto text-center md:w-11/12 xl:w-9/12">
              <h1 className="mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight">
                Your personal 
                {" "}
                <span className="block w-full py-2 text-transparent bg-clip-text leading-12 bg-gradient-to-r from-pink-400 to-purple-500 inline">
                  MCAT
                </span>
                {" "} AI study buddy 🧠
              </h1>
              <p className="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24">
                Score above a 510+ on the MCAT with Kat 👱‍♀️
              </p>
              <p className="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24">
                Review, anywhere, anytime! 
                10,000+ MCAT questions and explanations at your fingertips.
              </p>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
              {/* <form
                  data-netlify="true"
                  name="join"
                  id="join"
                  method="post"
                  onSubmit={handleSubmit}
                  data-netlify-recaptcha="true"
                >
                  <input type="hidden" name="form-name" value="join" />
                  <div className="relative">
                    <input
                      type="email"
                      name="email"
                      placeholder="💌 Your Email"
                      id="email"
                      className="mr-2 rounded-lg border-transparent  appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                      onInput={e => setEmail(e.target.value)}
                      onClick={() => {track('HeroCTXEmailInput Clicked')}}
                      required
                    /> */}
                    {/* <input
                      type="tel"
                      name="number"
                      id="number"
                      onInput={e => setNumber(e.target.value)}
                      onClick={() => {track('HeroCTXEmailInput Clicked')}}
                      className="mr-2 mt-4 rounded-lg border-transparent  appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-pink-600 focus:border-transparent"
                      placeholder="📞 123-456-7890"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    /> */}
                    {/* <div className="pt-5 flex items-center justify-center">
                      <input
                        className=" rounded-md shadow flex items-center justify-center px-2 py-1 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                        type="submit"
                        value="Request Access"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                   */}
                    {/* <a
                        // href="https://chrome.google.com/webstore/detail/quiz-me-ai-remember-what/ekcjbijhkfodohbpmenhiiiopfopgkah"
                        href="#pricing"
                        className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-pink-600 rounded-md sm:w-auto sm:mb-0"
                      >
                        Request Access
                        <svg
                          className="w-4 h-4 ml-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </a> */}
                  {/* </div>
                </form> */}
              </div>
            </div>
          </div>
          {open && <Alert onClose={handleClose} severity="error">
            <AlertTitle>Error</AlertTitle>
            An error occured saving your email please contact support — <strong>support@quizme.ai</strong>
          </Alert>}
        </section>
      </Fade>
      )}
    </VisibilitySensor>
  )
}
