import React from "react"
import { Link } from "gatsby"
import PersonImg1 from "../images/1.png"
import PersonImg2 from "../images/2.png"
import PersonImg3 from "../images/3.png"
import PersonImg4 from "../images/4.png"
import PersonImg5 from "../images/5.png"
import PersonImg6 from "../images/6.png"
import PersonImg7 from "../images/7.png"
import PersonImg8 from "../images/8.png"
import PersonImg9 from "../images/9.png"
import PersonImg10 from "../images/10.png"
import PersonImg11 from "../images/11.png"
import PersonImg12 from "../images/12.png"
import PersonImg13 from "../images/13.png"
import PersonImg14 from "../images/14.png"
import PersonImg15 from "../images/15.png"


export default function Testimonials() {
  return (
    <div id="love">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 mb-1 mt-12">
        <div className="pt-1 md:pt-1">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center">
            <div className="max-w-3xl mx-auto text-center pb-12">
              <h2 className="mb-4 text-3xl lg:text-4xl font-bold font-heading">
                ❤️ Love
              </h2>
            </div>
          </div>
        </div>
      </div>
    
    <div className="w-full flex flex-col md:flex-row gap-4 mb-8 md:mb-0 p-1 flex-between items-center mx-auto max-w-5xl">
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          This flipped my routine for MCAT prep around! Saved me tonns of time on practice. It tailors questions to my weak areas and provides detailed explanations.
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg1}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Ralph A.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Nursing student at UBC
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          I can't believe how much my MCAT scores improved with this AI study buddy. It covers all subjects thoroughly, and the instant feedback is invaluable. Highly recommend!
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg2}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Kayla B.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              MD candidate at John Hopkins
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          This is an fantastic study buddy man. Really love the accissiblitiy, helps with time constraints with work and all that
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg3}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Prashant
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Premed student
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          As a busy student, I needed a study tool that fits my schedule. It does just that. It's user-friendly and makes MCAT prep more manageable.
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg4}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Chen Ng.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Premed student at Duke University
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div className="w-full flex flex-col md:flex-row gap-4 mb-8 md:mb-0 flex-between p-1 items-center mx-auto max-w-5xl">
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          I was skeptical at first, but this AI proved me wrong. My MCAT practice scores went from average to outstanding in just a few weeks. Thank you!
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg5}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Devana
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Premed 
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          Their AI is so advanced I love how it just knows so much!
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg6}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Charlotte M.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Student at Kent State University
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          This is a great supplement on my commute. Great to practice on the go. Definitely worth it!
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg7}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Melanie L.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Student
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          I credit this for helping me achieve my dream MCAT score. The adaptive learning is a game-changer, and the explanations are spot on.
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg8}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Allison H.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Student at UoT
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
    </div>


    <div className="w-full flex flex-col md:flex-row gap-4 mb-8 md:mb-0 flex-between p-1 items-center mx-auto max-w-5xl">
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          The MCAT study buddy is like having a study group at your fingertips. It's been a huge confidence booster, and I feel more prepared than ever.
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg9}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Jonathan Wong
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Premed at UoT
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          I can't recommend this MCAT AI enough. It's affordable, efficient, and the progress tracking keeps me motivated. A must-have for MCAT prep!
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg10}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Mary O.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Student at Oxford Medical
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          Awesome addition to my study routine. It does well with identifying weak points and provides targeted practice. 
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg11}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Fathima M.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Premed at Harvard Medical School
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          I'm amazed by how much this chatbot has improved my MCAT scores from the first time I took the exam. The AI adapts to my needs, and the explanations are clear. I'm so grateful I found it!
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg12}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Elizabeth 
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Student at NYU Grossman School of Medicine
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
    </div>



    <div className="w-full flex flex-col md:flex-row gap-4 mb-8 md:mb-0 flex-between p-1 items-center mx-auto max-w-5xl">
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          This AI is a fantastic study companion. It challenges me and provides instant feedback. My MCAT scores are on the rise thanks to this tool.
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg13}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Kevin C.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              Student at UBC Medical School
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          A game-changer for MCAT prep. It's like having a personal tutor available 24/7. My confidence and scores have soared since I started using it.
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg14}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Janice L.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              MD candidate at John Hopkins
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
        <p className="text-gray-600 dark:text-white">
          <span className="font-bold text-pink-500 text-lg">“</span>
          Bruhhh, powerful tool for MCAT prep, and the interactive quizzes are dope.
          <span className="font-bold text-pink-500 text-lg">”</span>
        </p>
        <div className="flex items-center mt-4">
          <Link to="#" className="block relative">
            <img
              alt="profile"
              src={PersonImg15}
              className="mx-auto object-cover rounded-full h-10 w-10 "
            />
          </Link>
          <div className="flex flex-col ml-2 justify-between">
            <span className="font-semibold text-pink-500 text-sm">
              Trevon L.
            </span>
            <span className="dark:text-gray-400 text-xs flex items-center">
              UCLA Med School
              {/* <img src="/icons/rocket.svg" className="ml-2 h-4 w-4" /> */}
            </span>
          </div>
        </div>
      </div>
    </div>


    </div>
  )
}
